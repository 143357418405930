@import '_theme.scss';

$leftpart_padding: 20px;
$highligh_decale: 25px;

div.article-illustration.top-illustration {
    background-size: cover;
    height: 600px;
    margin-bottom: -160px;
}

div.article-follower {
    position: relative;
}

div.article-container {
    background: #ffffff;
    color:$primary-font-color;
    border-radius: $default-radius;
    position: relative;
    .tags-line {
        margin-top: 20px;
        .ant-tag {
            color:$default-darklightcolor;
            border-color: $default-darklightcolor;
            margin-right: 2px;
        }
    }

    
    h2 {
        color:$primary-font-color;
        font-size: 2.5em;
        margin-bottom: 0;
    }
    h3 {
        color:$primary-font-color;
        padding-left: 40px;
        @include md {
            padding-left: 0;
        }
    }

    div a {
        color:$link-color-article;
        text-decoration: underline;
    }

    div.full-text {
        div.rt-img-handler {
            padding: 0 10px;
        }
        img {
            @include polaroid;
        }
    }

    .article-highlight {
        background: #c4c4c4;
        position: relative;
        color: $default-darkcolor;
        left: -$highligh_decale;
        width: calc(100% + #{$highligh_decale * 2});
        border-radius: 3px;
        padding: 30px $highligh_decale;
        @include shadow;
        &.left {
            margin-left: 25px;
        }
    }

    .article-part {
        @include flex-container;
        &.end>div {
            padding: 5px;
        }
        &>div {
            padding-top: 30px;
            padding-bottom: 30px;
            &.both {
                padding-left: 60px;
                padding-right: 60px;
            }
            &.left {
                width: #{$picheight * $picratio + 2 * $leftpart_padding};
                padding-right: $leftpart_padding;
                padding-left: $leftpart_padding;
            }
            &.right {
                flex: 1;
                width: auto;
                padding-left: 15px;
                padding-right: 60px;

            }
        }
    }
}