@import "_theme.scss";

.article-actu {
    .meta-card-details {
        display: none;
    }
}

.toggle-actu {
    margin-bottom: 30px;
    color: $link-color;
    text-align: right;
    position: relative;
}
