@import "_theme.scss";

span.note {
    @include gland-color("%23000");
    &.light {
        @include gland-color("%23FFF");
    }
    span.div {
        font-size: 0.7em;
    }
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    padding-right: 0.9em;
    height: 0.9em;
    display: inline-block;
    line-height: 1em;
}