@import '_theme.scss';

div.top-illustration {
    background-size: cover;
    position: relative;
    height: 240px;
    margin-bottom: -130px;
    background-position: 50% 50%;
    &:after {
        content:" - ";
        display: block;
        position: absolute;
        bottom: 0;
        color:transparent;
        width: 100%;
        height: 100px;
        margin: 0;
        background: linear-gradient(0deg, $primary-background-color 0%, $primary-background-color 10%, rgba(0, 0, 0, 0) 100%);
    }
}
