@import '_theme.scss';

div.escape-card {
    &.full span.note {
        margin: 10px 20px 0 20px;
        font-size: 1em;
    }
    span.note {
        font-size: 1.5em;
        float: right;
        margin: 3px 16px 0 0;
    }
    &.full {
        .flexpart-2 {
            min-width: 300px;
            @include md {
                max-width: none;
            }
        }
        .flexpart-left {
            min-width: 400px;
            max-width: 400px;
            @include md {
                min-width: auto;
                max-width: none;
                width: 100%;
            }
        }
    }
    &.full {
        .t {
            max-width: calc(100% - 3.8em);
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .meta-card-details {
        .region {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
            display: inline-block;
        }
        .tags {
            margin-top: 20px;
            margin-bottom: 20px;
            line-height: 1.9em;
            .ant-tag {
                color:$default-darklightcolor;
                border-color: $default-darklightcolor;
                margin-right: 2px;
                &.closed-tag {
                    background: $default-darklightcolor;
                    color: #ffffff;
                }
            }
            &.taggold {
                .ant-tag {
                    background: #c08a00;
                    border-color: #c08a00;
                    color: #ffffff;
                    @include bold;
                }
            }
        }
    }
}