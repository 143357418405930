@import "_theme.scss";

div.all-enseignes {
    position: relative;
    div.list {
        @include flex-container;
        @include md {
            flex-direction: row;
        }
    }
}