@import '_theme.scss';

div.enseigne-main {
    div.meta-card.full {
        margin: 10px 0;
    }
    div.logo-area {
        text-align: center;
        padding-top: 20px;
    }
    div.googlemaps {
        border-radius: 0 0 $default-radius $default-radius;
        overflow: hidden;
    }
    p.loc {
        @include light;
        font-style: italic;
    }
    span.closed-info {
        font-size: 0.7em;
        font-style: italic;
    }
}