@import '_theme.scss';

div.selection-main {
    div.meta-card.full {
        margin: 10px 0;
    }
    div.page-back {
        margin-bottom: -70px;
        position: relative;
        top: -70px;
    }
}
div.all-selections {
    position: relative;
    margin-bottom: 60px;
}