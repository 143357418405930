$primary-background-color: #171717;
$primary-font-color: #333333;
$primary-error-font-color: #d61212;

$default-radius: 10px;
$default-darkcolor: #171717;
$default-darklightcolor: #8c8d8c;

$default-page-padding: 5%;
$mobile-page-padding: 5px;
$default-card-margin: 5px;


$link-color: #ffffff;
$link-color-hover: #9e9e9e;
$link-color-article: #177ddc;

$zoning-background: #2c2c2c;

$default-anim-duration: 0.5s;

$default-fontSize: 16px;

/* Card setup */
$picheight: 210px;
$picheight_md: 190px;
$picratio: 16/9;
/*****/



@mixin bold {
    font-weight: 700;
}
@mixin light {
    font-weight: 300;
}
@mixin regular {
    font-weight: 400;
}

@mixin flex-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    @include md {
        flex-direction: column;
    }
}

@mixin shadow {
    box-shadow: 5px 5px 17px 1px #13131330;
}

@mixin gland-color($color) {
    background-image: url('data:image/svg+xml;utf8,<svg width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.8215 18.4144V24.1825C22.8215 26.5067 21.5518 28.6157 19.889 30.2773C17.6286 32.5378 14.5368 34 12.9949 34C11.453 34 8.36916 32.5377 6.1085 30.2773C4.44702 28.6158 3.176 26.5067 3.176 24.1825V18.4144H22.8215ZM1.84388 16.6254C1.3485 16.6254 0.94873 16.2256 0.94873 15.7382C0.94873 9.65152 5.74205 6.35896 11.3745 5.91288C11.3984 4.89022 11.6627 3.66038 12.101 2.62178C12.3639 2.01481 12.684 1.46367 13.0519 1.03201C13.4835 0.520681 13.9949 0.160757 14.5779 0.0412132C14.9857 -0.038476 15.4093 -0.00660098 15.8317 0.176683L18.7881 1.43043C18.9793 1.50215 19.1401 1.63762 19.2516 1.8302C19.5318 2.32558 19.2994 2.82895 18.8279 3.08395C17.6937 3.72278 16.2315 4.54623 16.0641 5.99123C20.8653 6.94217 25.0517 10.5693 25.0517 15.737C25.0517 16.2244 24.6519 16.6242 24.1565 16.6242H1.84402L1.84388 16.6254ZM17.8212 24.7813C17.8212 24.2859 18.2209 23.8862 18.7163 23.8862C19.2117 23.8862 19.6115 24.2859 19.6115 24.7813C19.6115 26.1479 18.885 27.3618 17.934 28.3207C16.6564 29.5904 14.882 30.4138 13.9802 30.4138C13.4848 30.4138 13.085 30.014 13.085 29.5187C13.085 29.0312 13.4848 28.6315 13.9802 28.6315C14.5234 28.6315 15.7134 28.0086 16.6723 27.0576C17.3271 26.4029 17.8225 25.6034 17.8225 24.7812L17.8212 24.7813Z" fill="#{$color}"/></svg>');
}

@mixin polaroid {
    border: 6px #ffffff solid;
    @include shadow;
    &.r0 {
    }
    &.r1 {
        transform: rotate(1deg);
    }
    &.r2 {
        transform: rotate(2deg);
    }
    &.rm1 {
        transform: rotate(-1deg);
    }
    &.rm2 {
        transform: rotate(-2deg);
    }
}









$screen-sm-min: 800px;
// Small tablets (portrait view)
$screen-md-min: 1178px;
// Tablets and small desktops
$screen-lg-min: 1400px;
// Tablets and small desktops
$screen-xxl-min: 2100px;

// Small devices
@mixin sm {
    @media screen and (max-width: #{$screen-sm-min}) {
        @content;
    }
 }
// Medium devices
@mixin md {
   @media screen and (max-width: #{$screen-md-min}) {
       @content;
   }
}
// Large devices
@mixin lg {
    @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-lg-min}) {
       @content;
   }
}
// Extra large devices
@mixin xl {
   @media (min-width: #{$screen-lg-min}) {
       @content;
   }
}
// Extra large devices
@mixin xxl {
   @media (min-width: #{$screen-xxl-min}) {
       @content;
   }
}