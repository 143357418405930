@import '_theme.scss';

div.home {

    h3 {
        margin-top: 30px;
    }

    div.realisation {
        @include light;
        margin: 10px;
    }

    .main-group {
        @include flex-container;
        margin-top: 100px;
        align-items: center;
        & > div {
            width: 30%;
            min-width: 500px;
            text-align: center;
        }
        a {
            font-size: 1.3em;
            display: inline-flex;
            padding: 5px 15px;
            border: 1px solid $link-color;
            margin-top: 20px;
        }
    }
}