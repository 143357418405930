@import '_theme.scss';


@mixin goldleft-color($color) {
    background-image: url('data:image/svg+xml;utf8,<svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.67 233.63"><defs><style>.cls-1{fill:#{$color};}</style></defs><title>feuille-gauche</title><path class="cls-1" d="M113.34,196.93s-2.67-15.66,1.67-22.33,31-30.67,19-51.33-44,11-44,11,31-57,6.66-77.67-37,14.67-37,14.67S67.34-5.07,40,.27,40,71.6,40,71.6s-8-7-21.67-5S0,80.6,0,91.6s5,34.33,50,55.33c0,0-5.34-.33-16.67-3.66s-33-5-33.33,14,14.33,27,32.33,31.33,33,0,41.67,6,27.66,23,40.33,29.33,21.33,9.34,26,9.67,9.33-2.33,9.33-8.67a10.24,10.24,0,0,0-9.33-10.33c-4.33-.33-27.33,4.67-60-40.67s-31.67-103-31.67-103S66,158.93,113.34,196.93Z" transform="translate(0 0)"/></svg>');
}

@mixin goldright-color($color) {
    background-image: url('data:image/svg+xml;utf8,<svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.67 233.63"><defs><style>.cls-1{fill:#{$color};}</style></defs><title>feuille-droite</title><path class="cls-1" d="M36.33,196.93S39,181.27,34.67,174.6s-31-30.67-19-51.33,44,11,44,11-31-57-6.67-77.67S90,71.27,90,71.27,82.33-5.07,109.67.27s0,71.33,0,71.33,8-7,21.66-5,18.34,14,18.34,25-5,34.33-50,55.33c0,0,5.33-.33,16.66-3.66s33-5,33.34,14-14.34,27-32.34,31.33-33,0-41.66,6S48,217.6,35.33,223.93s-21.33,9.34-26,9.67S0,231.27,0,224.93A10.24,10.24,0,0,1,9.33,214.6c4.34-.33,27.34,4.67,60-40.67S101,70.93,101,70.93,83.67,158.93,36.33,196.93Z"/></svg>');
}


div.article-selections {
 margin-bottom: 60px;       
}

div.moretosee {
    margin: 60px 0;
}

div.in-selection {
    margin-bottom: 60px;
}

div.article-container {
    &.article-escape {
        div.full-text img {
            @include polaroid;
        }
    }
    &.article-enseigne {
        margin: 0;
    }
    div.glandus-or {
        background: #c08a00;
        line-height: 3em;
        font-size: 1.5em;
        @include md {
            font-size: 1em;
        }
        @include bold;
        border-radius: $default-radius $default-radius 0 0;
        color: #ffffff;
        text-align: center;
        p {
            margin-bottom: 0;

            &:before, &:after, span:before {
                content: "-";
                background-position: 100% 50%;
                background-repeat: no-repeat;
                background-size: contain;
                padding-left: 0.9em;
                margin-right: 0.8em;
                height: 0.9em;
                display: inline-block;
                line-height: 1em;
                overflow: hidden;
                text-indent: -5em;
            }
            
            &:before {
                @include goldleft-color("%23fff");
                transform: scale(2.2);      
                margin-right: 0.8em;          
            }
            &:after {
                @include goldright-color("%23fff");
                transform: scale(2.2);      
                margin-left: 0.3em;              
            }
            span:before {
                @include gland-color("%23fff");
                margin-right: 0.2em;
                
            }
        }
    }
    p.region {
        margin-bottom: 0;
        @include light;
        font-style: italic;
    }

    div.avantapres img {
        @include shadow;
        border: 6px #ffffff solid;
    }

    div.scenario {
        margin-top: 30px;
    }

    div.title-flex {
        @include flex-container;
        justify-content: space-between;
        align-items: center;
        .note {
            font-size: 2.5em;
            margin-right: 30px;
            i {
                font-size: 13px;
            }
        }
    }
    span.closed-info {
        font-size: 0.7em;
        font-style: italic;
    }
    
}