@import "_theme.scss";


.ant-select-item {
    span.smo {
        font-size: 0.7em;
    }
    &.ant-select-item-group {
        color: rgba(255, 255, 255, 0.45);
        cursor: default;
        border-bottom: 1px solid #7e7e7e;
        height: 1px;
        min-height: 1px;
        overflow: hidden;
        margin: 0 10px 10px 10px;
    }
}

div.browse-main {
    .browse-presets {
        position: relative;
        @include flex-container;
        @include md {
            flex-direction: row;
            margin: 0 10px;
        }
        &>div {
            margin: $default-card-margin;
        }

    }

    
    .browse-slice {
        max-width: 2000px !important;
    }

    .browse-sort {
        @include flex-container;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        div.sort-by {
            @include md {
                display: none;
            }
        }
    }

    .pagination {
        text-align: center;
        margin: 60px 0 30px 0;
    }

    .browse {
        @include flex-container;
        flex-wrap: nowrap;
        justify-content: flex-start;
        .browse-filter {
            min-width: 300px;      
            max-width: 400px;     
            padding: 0 15px 50px 10px;
            @include md {
                width: 100%;             
                max-width: none;         
            }
            .ant-row.ant-form-item {
                margin-bottom: 5px;
                padding: 0 5px;
                .ant-form-item-control-input-content {
                    padding-left: 20px;
                }
            }
            p.top-sec {
                margin-left: 6px;
            }
            .chkbx-tags {
                @include flex-container;
                flex-direction: column;
                @include md {
                    flex-direction: row;
                }
            }
        }
    
        .browse-result {
            flex: 1;
            position: relative;
            margin-bottom: 50px;
            p.loading-spin {
                position: absolute;
                top:-8px;
                left: 19px;
                .ant-spin-dot-item {
                    background-color: #ffffff;
                }
            }
        }
    }
}