@import '_theme.scss';

div.home-carousel {

    .carousel {
        position: relative;
    }

    .text, .illustration {
        height: 600px;
        width: 100%;
        color: #ffffff;
        @include md {
            height: 400px;
        }
        @include sm {
            height: 300px;
        }
    }
    .text {
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 0;
        left: 0;
        @include flex-container;
        align-content: flex-end;
        align-items: flex-end;
        @include md {
            flex-direction: row;
        }
        &>div {
            background: rgba(23,23,23,0);
            background: linear-gradient(0deg, $primary-background-color 0%, $primary-background-color 10%, rgba(255,255,255,0) 100%);
            width: 100%;
            font-size: 2em;
            padding-top: 200px;
            @include md {
                padding-top: 100px;
            }  
            @include sm {
                padding-top: 0;
                background: none;
            }            
        }
    }
    .illustration {
        background-size: cover;
        background-position: center;
    }

    .slick-dots-bottom {
        bottom: -30px;
        li button {
            background: #ffffff;
        }
        li.slick-active button {
            background: #c0c0c0;
        }
    }
}