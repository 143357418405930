@import '_theme.scss';

div.meta-card {
    position: relative;
    &.full {
        position: relative;
        padding-left: 60px;
        margin: 10px;
    }
    &.reduce {
        width: #{$picheight * $picratio};
        @include md {
            width: #{$picheight_md * $picratio};
        }
        &.search-card {
            width: 100px;
            .meta-card-mini,
            .meta-card-minilink,
            .meta-card-details {
                width: 100px;
            }
            .meta-card-minilink {
                font-size: 1.5em;
            }
        }
    }
    &.colored {
        .meta-card-mini {
            filter: grayscale(100%) contrast(30%);
        }
        &:hover .meta-card-mini {
            animation-duration: $default-anim-duration;
            animation-name: rollonpiccolor;
            background-size: 110%;
            filter: grayscale(0%) contrast(50%);
        }
    }
    a:hover {
        text-decoration: none;
        color: $link-color;
    }

    &:hover .meta-card-mini {
        animation-duration: $default-anim-duration;
        animation-name: rollonpic;
        background-size: 110%;
        filter: grayscale(0%);
    }
    @keyframes rollonpic {
        from {
            background-size: 100%;
            filter: grayscale(70%);
        }
        to {
            background-size: 110%;
            filter: grayscale(0%);
        }
    }
    @keyframes rollonpiccolor {
        from {
            background-size: 100%;
            filter: grayscale(100%) contrast(30%);
        }
        to {
            background-size: 110%;
            filter: grayscale(0%) contrast(50%);
        }
    }


    .meta-card-minilink {
        display: block;
        border-radius: $default-radius;
        width: #{$picheight * $picratio};
        height: $picheight;
        text-decoration: none;
        color: $link-color; 
        @include md {
            width: #{$picheight_md * $picratio};
            height: $picheight_md;
            &.compact {
                height: #{$picheight_md/2};
            }
        }
        &.compact {
            height: #{$picheight/2};
        }
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: scroll;
        background-position-y: 50%;
        @include shadow;
        position: absolute;
        top:0;
        left: 0;
        &.full {
            margin: 25px 0 0 0;
            position: absolute;
            top: 0;
            left: 0;
        }
        p {
            @include flex-container;
            border-radius: $default-radius;
            width: 100%;
            height: 100%;
            align-items: center;
            padding: 0 20px;
            font-size: 1.28em;
            text-align: center;
            text-transform: uppercase;
            span {
                @include bold;
            }
            span.chevron {
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
        }
    }
    .meta-card-mini {
        display: block;
        border-radius: $default-radius;
        width: #{$picheight * $picratio};
        height: $picheight;
        @include md {
            width: #{$picheight_md * $picratio};
            height: $picheight_md;
            &.compact {
                height: #{$picheight_md/2};
            }
        }
        &.compact {
            height: #{$picheight/2};
        }
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: scroll;
        background-position-y: 50%;
        box-shadow: 3px 3px 15px 5px #13131330;
        &.full {
            margin: 25px 0 0 0;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .meta-card-details {
        &.full {
            display: block;
            .flexpart {
                @include flex-container;
                justify-content: flex-start;
            }
            overflow: hidden;
            background: #ffffff;
            border-radius: $default-radius;
            min-height: #{$picheight + 3 * 30};        
            padding: 30px 20px 20px #{$picheight * $picratio - 5px};
            @include md {
                min-height: #{$picheight_md + 3 * 30};        
                padding: 30px 20px 20px #{$picheight_md * $picratio - 5px};
                &.compact {
                    min-height: #{($picheight_md/2) + 3 * 30}; 
                }
            }
            &.compact {
                min-height: #{($picheight/2) + 3 * 30};        
            }
            color:$default-darklightcolor;
            .title {
                font-size: 2em;
                color: $default-darkcolor;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            @include lg {
                flex-direction: column;
            }
            @include md {
                padding: #{$picheight_md + 50} 10px 10px 10px;
                align-items: flex-start;
            }
            .ant-skeleton span
            ,.ant-skeleton h3
            ,.ant-skeleton li {
                @include shadow;
            }
            a, a:hover {
                color:$default-darklightcolor;
            }
        }
        &.reduce {
            .title {
                font-size: 1em;
                color: #ffffff;
                margin: 5px 0 -4px 0;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .sub-title {
                font-style: italic;
                @include light;
            }
        }
        p {
            margin: 0;
        }
        .date {
            color: $default-darkcolor;
            text-transform: capitalize;
            margin-bottom: 10px;
        }
        .title {
            font-size: 2em;
            color: $default-darkcolor;
            white-space: nowrap;
        }
        .sub-title, .sup-title {
            font-size: 1em;
            color:$default-darklightcolor;
        }
        .sup-title {
            font-style: italic;
        }
        .description {
            padding-right: 10px;
            @include md {
                padding: 0 20px 20px 0;
            }
        }
    }
}